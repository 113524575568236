import React from 'react';
import './Services.css';

const Services = () => {
    return (
        <div className='services py-2'>
            <div className='container my-5'>
            <div className='text-center my-5'>
                <h2 className='fw-bold custom-color'>Services</h2>
                <p>Few of the services provided for client</p>
                <hr/>

            </div>
            <div data-aos="fade-up" data-aos-duration="1000" className="row row-cols-1 row-cols-md-3 g-4">
  <div className="col">
    <div className="card text-center h-100">
    <i className="fas fa-tv"></i>
      <div className="card-body">
        <h5 className="card-title">Web Design</h5>
        <p className="card-text  text-muted">
        We design your desired website according to your need. We use HTML, CSS, JavaScript, PHP, WordPress and OpenCart.
        </p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card text-center h-100">
    <i className="fas fa-laptop-code"></i>
      <div className="card-body">
        <h5 className="card-title">Web Development</h5>
        <p className="card-text  text-muted">We develop your dreem website according to your requirements.We use HTML,CSS,JavaScript,PHP,WordPress,OpenCart.</p>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card text-center h-100">
    <i className="fas fa-palette"></i>
      <div className="card-body">
        <h5 className="card-title">Graphic Design</h5>
        <p className="card-text text-muted">
        We provide graphic design supports any time from anywhere. Our dedicated team maintenance your site.
        </p>
      </div>
    </div>
  </div>
  
</div>
        </div>
        </div>
    );
};

export default Services;