import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';

const About = () => {
    return (

        <div className='container about my-5'>
            <div className=''>
                <div className='row align-items-center justify-content-center'>
                    <div data-aos="fade-right" className='border border-0 col-lg-5'>
                        <img style={{
                            backgroundImage: `url('../../../Images/bg2.png')`,
                            backgroundPosition: 'cover',
                            backgroundSize: '100%'
                        }}
                            src="../../../Images/kawsar.png" className=' img-fluid img-style' alt="" />
                    </div>
                    <div data-aos="zoom-out-left" className='col-lg-7'>
                        <h2 className='fw-bold custom-color'>About Me</h2>
                        <h6 className=''>I'm a <strong>Frontend Web Developer</strong>. Also Founder of <strong>SK Design</strong></h6>
                        <p>My name is Md Kawsar Hosan. I am a freelancer based in Bangladesh and i have been building noteworthy websites for years. For this time i've done all types of projects.<br /><br />

                            I help convert a vision and an idea into meaningful and useful products. Having a sharp eye for product evolution helps me prioritize tasks, iterate fast and deliver faster. If you're interested in working with me, don't hesitate to hire me</p>

                        <h6><span className='fw-bold'>From</span> Dhaka, Bangladesh</h6>
                        <a target="_blank" rel="noreferrer" href='https://drive.google.com/uc?export=download&id=1ze3_Y01CU7k77iNIKGaXmJJiPJEt9IGI'><button className='btn mt-3 btn-primary custom-btn'>Download Resume <i class="fas fa-download"></i></button></a>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default About;