import React from 'react';
import { Link } from 'react-router-dom';
import './EducationTimeline.css';

const EducationTimeline = () => {
    return (
        <div>
            <div className="container my-5">
			<div className='text-center my-5'>
            <h2 className='fw-bold custom-color'>Educational Background</h2>
            <p>Skill important, but education too.</p>
			<hr/>

        </div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="main-timeline">
                <div  class="timeline">
                    <div class="timeline-content">
                        <span class="timeline-year">B.Sc</span>
                        <div class="timeline-icon">
						<i class="fas fa-graduation-cap"></i>
                        </div>
                        <h5 class="title">Computer Science & Engineering</h5>
                        <p class="description">
                            World University of Bangladesh.<br/>
							September, 2021 to Present.
                        </p>
                    </div>
                </div>
                <div class="timeline">
                    <div class="timeline-content">
                        <span class="timeline-year">Diploma</span>
                        <div class="timeline-icon">
						<i class="fas fa-user-graduate"></i>
                        </div>
                        <h5 class="title">Computer Science & Engineering</h5>
                        <p class="description">
                            Chandpur Polytechnic Institute.<br/>
							Augest, 2016 to July, 2020.<br/>
							CGPA- 3.54 out of 4
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
			</div>
        </div>
    );
};

export default EducationTimeline;