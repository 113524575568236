import React from 'react';

import About from '../About/About';
import Contact from '../Contact/Contact';
import Counter from '../Counter/Counter';
import EducationTimeline from '../EducationTimeline/EducationTimeline';

import Hero from '../Hero/Hero';
import Portfolio from '../Portfolio/Portfolio';
import Services from '../Services/Services';
import Skillbar from '../Skillbar/Skillbar';
import Client from '../Client/client';


const Home = () => {
    return (
        <div>
           
            <Hero></Hero>
            
            <About></About>
            <Services></Services>
            <EducationTimeline></EducationTimeline>
            <Skillbar></Skillbar>
            <Portfolio></Portfolio>
            {/* <Counter></Counter> */}
            <Client></Client>
            <Contact></Contact>
          
        </div>
    );
};

export default Home;