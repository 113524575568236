import React from 'react';
import { Form } from 'react-bootstrap';

const Contact = () => {
    return (
        <>
        <div>
            <div className='container'>
                <div className='text-center my-5'>
                    <h2 className='fw-bold custom-color'>Contact Us</h2>

                    <hr />

                </div>
                <div className='row align-items-center'>
                    <div className='col-lg-6'>
                        <img className='w-100' src="../../../Images/contact.png" alt=""/>
                    </div>
                    <div className='col-lg-6'>
                    <div  className=''>
                    <Form class="rounded text-center border border-black p-5">


                        <input type="text" id="defaultContactFormName" class="form-control mb-4" placeholder="Name" />
                        <input type="email" id="defaultContactFormEmail" class="form-control mb-4" placeholder="Email" />

                        <div class="form-group">
                            <textarea class="form-control rounded-0" id="exampleFormControlTextarea2" rows="5" placeholder="Message"></textarea>
                        </div>
                        <div class="custom-control custom-checkbox mb-4">

                        </div>

                        <button class="btn custom-btn" type="submit">Send</button>
                    </Form>
                    
                </div>
                    </div>
                </div>
            </div>
            </div>
            {/* <div className=' contact-img'>
                        <img className='w-100' src="../../../Images/hero-bg.jpg" alt="" />
                    </div> */}
        </>
    );
};

export default Contact;