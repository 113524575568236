import React from 'react';
import { Link } from 'react-router-dom';
import './client.css';

const Client = () => {
    return (

        <div className="auto-container client py-2">
            <div className='text-center my-5'>
                <h2 className='fw-bold custom-color'>Our Valuable Clients</h2>
                <p>Choose our service to get the best quality and affordable price</p>
                <hr />

            </div>

            <section id="clients" className="section-bg">
                <div className="container">
                    <div className="row no-gutters clients-wrap clearfix wow fadeInUp"
                        // style="visibility: visible; animation-name: fadeInUp;"
                        style={{ visibility: "visible" }}>

                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (1).png" className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (2).png" className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (3).png" className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (4).png" className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (5).png" className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (6).png" className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (7).png" className="img-fluid" alt="" />
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (8).png" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (9).png" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (10).png" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (11).png" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-xs-6">
                            <div className="client-logo">
                                <img src="../../clients/client (12).png" className="img-fluid" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>

    );
};

export default Client;





