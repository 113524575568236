import React from 'react';
import './Portfolio.css';

const Portfolio = () => {
    return (
        <div className=' py-2'>
            <div className='container my-5'>
                <div className='text-center my-5'>
                    <h2 className='fw-bold custom-color'>Portfolio</h2>
                    <p>Few of the works for client</p>
                    <hr />

                </div>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col">
                        <div className="card custom-shadow text-center h-100">
                            <div className="hovereffect">
                                <img className="img-responsive w-100" src="../../../Images/Projects/appartementhunt.jpg" alt="" />
                                <div className="text-center overlay bg-primary">
                                    <a href="/"><h2 className='me-2'>Details</h2></a>
                                    {/* <i class="fas fa-link"></i> */}
                                    <a target='_blank' rel='noreferrer' href="https://appartment-hunt-8fa1e.web.app/"><h2 className='me-2'>Live</h2></a>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card custom-shadow text-center h-100">
                            <div className="hovereffect">
                                <img className="img-responsive w-100" src="../../../Images/Projects/quriarbox.jpg" alt="" />
                                <div className="text-center overlay bg-primary">
                                    <a href="/"><h2 className='me-2'>Details</h2></a>
                                    {/* <i class="fas fa-link"></i> */}
                                    <a target='_blank' rel='noreferrer' href="https://quriarbox-delivery-50f51.web.app/"><h2 className='me-2'>Live</h2></a>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card custom-shadow text-center h-100">
                            <div className="hovereffect">
                                <img className="img-responsive w-100" src="../../../Images/Projects/doctime.jpg" alt="" />
                                <div className="text-center overlay bg-primary">
                                    <a href="/"><h2 className='me-2'>Details</h2></a>
                                    {/* <i class="fas fa-link"></i> */}
                                    <a target='_blank' rel='noreferrer' href="https://doctime-react.netlify.app/"><h2 className='me-2'>Live</h2></a>



                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row row-cols-1 row-cols-md-3 my-2 g-4">
                    <div className="col">
                        <div className="card custom-shadow text-center h-100">
                            <div className="hovereffect">
                                <img className="img-responsive w-100" src="../../../Images/Projects/learnwithbondhu.jpg" alt="" />
                                <div className="text-center overlay bg-primary">
                                    <a href="/"><h2 className='me-2'>Details</h2></a>
                                    {/* <i class="fas fa-link"></i> */}
                                    <a target='_blank' rel='noreferrer' href="https://learnwithbondhu.netlify.app/"><h2 className='me-2'>Live</h2></a>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card custom-shadow text-center h-100">
                            <div className="hovereffect">
                                <img className="img-responsive w-100" src="../../../Images/Projects/jadoo.jpg" alt="" />
                                <div className="text-center overlay bg-primary">
                                    <a href="/"><h2 className='me-2'>Details</h2></a>
                                    {/* <i class="fas fa-link"></i> */}
                                    <a target='_blank' rel='noreferrer' href="https://kawsarhosan.github.io/travelling-site/"><h2 className='me-2'>Live</h2></a>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card custom-shadow text-center h-100">
                            <div className="hovereffect">
                                <img className="img-responsive w-100" src="../../../Images/Projects/digitalhat.jpg" alt="" />
                                <div className="text-center overlay bg-primary">
                                    <a href="/"><h2 className='me-2'>Details</h2></a>
                                    {/* <i class="fas fa-link"></i> */}
                                    <a target='_blank' rel='noreferrer' href="https://kawsarhosan.github.io/digital-haat/"><h2 className='me-2'>Live</h2></a>



                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Portfolio;