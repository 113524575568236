import React from 'react';

import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./Header.css";

const Header = () => {
  return (
    <div>
      <Navbar sticky='top' bg='myBg' expand="lg">
        <Container className=''>
          <Navbar.Brand>
            <h3 className='fw-bold title-text'>Md Kawsar Hosan</h3>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">


            <Nav
              className="ms-auto my-2 my-lg-0 text-white"

              navbarScroll
            >
              <Nav.Link><Link to="/home">Home</Link></Nav.Link>
              <Nav.Link><Link to="/about">About</Link></Nav.Link>
              <Nav.Link><Link to="/services">Serivces</Link></Nav.Link>
              <Nav.Link><Link to="/portfolio">Portfolio</Link></Nav.Link>
              <Nav.Link><Link to="/clients">Clients</Link></Nav.Link>
              <Link to="/contact">
                <Button className='custom-btn'>Contact</Button>
              </Link>

            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;