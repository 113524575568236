
import './App.css';
import Home from './Components/Home/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Contact from './Components/Contact/Contact';
import Portfolio from './Components/Portfolio/Portfolio';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import About from './Components/About/About';
import Services from './Components/Services/Services';
import Skillbar from './Components/Skillbar/Skillbar';
import EducationTimeline from './Components/EducationTimeline/EducationTimeline';
import Client from './Components/Client/client';

function App() {
  return (
    <div className="App">
          <Router>
              <Header/>
            <Switch>
                <Route exact path="/">
                    <Home></Home>
                </Route>
                <Route exact path="/home">
                    <Home></Home>
                </Route>
                <Route path='/about'>
                  <About></About>
                  <Skillbar></Skillbar>
                  <EducationTimeline></EducationTimeline>
                </Route>
                <Route path='/clients'>
                  <Client></Client>
                </Route>
                <Route path='/services'>
                  <Services></Services>
                </Route>
                <Route path='/contact'>
                    <Contact></Contact>
                </Route>
                <Route path='/portfolio'>
                    <Portfolio></Portfolio>
                </Route>
            </Switch>
            <Footer/>
          </Router>
    </div>
  );
}

export default App;
