import React from 'react';
import { Card } from 'react-bootstrap';
import './Hero.css';
// import bg from '../../../Images/hero-bg.jpg';

const Hero = () => {
    return (
        <>
            <div style={{backgroundImage: `url('../../../Images/hero-bg.jpg')`, backgroundPosition: 'fixed'}}  className=' hero '>
            <div  className='text-white text-center d-lg-flex justify-content-center align-items-center'>
                <div data-aos="fade-up" >
                    <h4 >Hello, This is <span className="fw-bold custom-color">Md Kawsar Hosan</span></h4>
                    <h1 className="fw-bold">I'm Frontend Web Developer</h1>
                    <p className="text-white lh-5 ">“I'm Md Kawsar Hosan. I'm a Frontend Web Developer. <br/>I have two years of industy experiences and have a part of design and development projects for many exceptional..."</p>
                   
                    <button className='mt-4 btn btn-primary custom-btn'>Hire Me <i class="fas fa-user-plus"></i></button>
                </div>




            {/* <Card className="bg-dark text-white">
                <Card.Img src="../../../Images/hero-bg.jpg" className='hero-bg w-100' alt="Card image" />
                <Card.ImgOverlay>

                    <Card.Text className='mt-5 pt-5 text-center'>
                        <h4 >Hello, This is <span className="fw-bold custom-color">Md Kawsar Hosan</span></h4>
                        <h1 className="fw-bold">I'm Frontend Web Developer</h1>
                        <p className="text-muted lh-5 ">“I'm Md Kawsar Hosan. I'm a Frontend Web Developer. <br />I have two years of industy experiences and have a part of design and development projects for many exceptional..."</p>
                        <button className='btn custom-btn'>Enroll Now</button>
                    </Card.Text>

                </Card.ImgOverlay>
            </Card> */}
            {/* <div>
                    <img className="w-100" src="/img/hero/io2.png" alt="" />
                </div> */}
            </div>
        </div>

        </>
    );
};

export default Hero;