import { height } from 'dom-helpers';
import React from 'react';
import './Skillbar.css';



const Skillbar = () => {



    return (
        <div className='custom-bg3 py-1'>
            <div className='container my-5'>
                <div className='text-center my-5'>
                    <h2 className='fw-bold custom-color'>My Skills</h2>
                    <p>“Schooling doesn't assure employment but skill does.”</p>
                    <hr />

                </div>
                <div className='row g-5'>
                    <div className='col-lg-5'>
                        <img className='w-100' src="../../../Images/skill.png" alt="" />
                    </div>
                    <div className='col-lg-7 '>

                        <div className='item' style={{ lineHeight: '5px', marginBottom: '25px' }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p>HTML</p>
                                <p>95%</p>
                            </div>
                            <div className="progress-div" style={{ width: "100%" }}>
                                <div style={{ width: "95%", backgroundColor: "#1c917e" }} className="progress " />
                            </div>
                        </div>

                        <div className='item' style={{ lineHeight: '5px', marginBottom: '25px' }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p>CSS</p>
                                <p>90%</p>
                            </div>
                            <div className="progress-div" style={{ width: "100%" }}>
                                <div style={{ width: "90%", backgroundColor: "rgb(244 103 93)" }} className="progress " />
                            </div>
                        </div>

                        <div className='item' style={{ lineHeight: '5px', marginBottom: '25px' }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p>BOOTSTRAP</p>
                                <p>92%</p>
                            </div>
                            <div className="progress-div" style={{ width: "100%" }}>
                                <div style={{ width: "92%", backgroundColor: "rgb(97 107 241)" }} className="progress " />
                            </div>
                        </div>

                        <div className='item' style={{ lineHeight: '5px', marginBottom: '25px' }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p>TAILWIND CSS</p>
                                <p>85%</p>
                            </div>
                            <div className="progress-div" style={{ width: "100%" }}>
                                <div style={{ width: "85%", backgroundColor: "rgb(27 78 131)" }} className="progress " />
                            </div>
                        </div>

                        <div className='item' style={{ lineHeight: '5px', marginBottom: '25px' }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p>JAVASCRIPT</p>
                                <p>68%</p>
                            </div>
                            <div className="progress-div" style={{ width: "100%" }}>
                                <div style={{ width: "68%", backgroundColor: "rgb(231 177 27)" }} className="progress " />
                            </div>
                        </div>

                        <div className='item' style={{ lineHeight: '5px', marginBottom: '25px' }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p>React</p>
                                <p>75%</p>
                            </div>
                            <div className="progress-div" style={{ width: "100%" }}>
                                <div style={{ width: "75%", backgroundColor: "rgb(108 108 133)" }} className="progress " />
                            </div>
                        </div>

                        <div className='item' style={{ lineHeight: '5px', marginBottom: '25px' }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <p>Figma | XD | PSD</p>
                                <p>85%</p>
                            </div>
                            <div className="progress-div" style={{ width: "100%" }}>
                                <div style={{ width: "85%", backgroundColor: "rgb(43 136 161)" }} className="progress " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};


export default Skillbar;