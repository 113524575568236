import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <div>
      {/* <!-- Footer --> */}
      <footer className="text-center text-lg-start bg-light text-light pt-3">
        {/* <!-- Section: Social media --> */}
        <div className='custom-bg'>



          <section>
            <div className="container text-light text-center text-md-start pt-3 mt-5">
              {/* <!-- Grid row --> */}
              <div className="row mt-3">
                {/* <!-- Grid column --> */}
                <div className="col-md-3 text-light col-lg-4 col-xl-3 mx-auto mb-4">
                  {/* <!-- Content --> */}
                  <h6 className="text-uppercase fw-bold mb-4">
                    <img src="/Images/sk3.png" alt="" />Md Kawsar Hosan
                  </h6>
                  <p className='text-light'>
                    I am Md Kawsar Hosan. I am a professional Front-End developer skilled in HTML5, CSS3, JS, Bootstrap, Tailwind, React, WordPress.</p>
                </div>
                {/* <!-- Grid column -->

        <!-- Grid column --> */}
                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  {/* <!-- Links --> */}
                  <h6 className="text-uppercase fw-bold mb-4">
                    Services
                  </h6>
                  <p>
                    <NavLink to="/" className="text-light">Web Design</NavLink>
                  </p>
                  <p>
                    <NavLink to="/" className="text-light">Web Development</NavLink>
                  </p>
                  <p>
                    <NavLink to="/" className="text-light">Graphics</NavLink>
                  </p>

                </div>
                {/* <!-- Grid column -->

        <!-- Grid column --> */}
                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                  {/* <!-- Links --> */}
                  <h6 className="text-uppercase fw-bold mb-4">
                    Find Me 
                  </h6>
                  <p>
                    <NavLink to={{pathname: "https://github.com/kawsarhosan"}} target="_blank" className="text-light">Github</NavLink>
                  </p>
                  <p>
                    <NavLink to={{pathname: "https://behance.net/kawsarhosan"}} target="_blank" className="text-light">Behance</NavLink>
                  </p>
                  <p>
                    <NavLink to={{pathname: "https://facebook.com/kawsarhosan.sk"}} target="_blank" className="text-light">Facebook</NavLink>
                  </p>
                  
                </div>
                {/* <!-- Grid column -->

        <!-- Grid column --> */}
                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 foot">
                  {/* <!-- Links --> */}
                  <h6 className="text-uppercase fw-bold mb-4">
                    Contact
                  </h6>
                  <p><i className="fas fa-home me-3"></i>Dhaka, Bangladesh.</p>
                  <p>
                    <i className="fas fa-envelope me-3"></i>skdesign.online@gmail.com
                  </p>
                  <p><i className="fas fa-phone me-3"></i>+880 1640522160</p>

                </div>
                {/* <!-- Grid column --> */}
              </div>
              {/* <!-- Grid row --> */}
            </div>
          </section>
        </div>
        {/* <!-- Section: Links  -->

  <!-- Copyright --> */}
        <div className="text-center custom-bg2 p-4" >
          Copyright © 2023 kawsarhosan.com || All Rights Reserved. Developed By <span className='fw-bold'>Md Kawsar Hosan</span>
        </div>

        {/* <!-- Copyright --> */}
      </footer>
      {/* <!-- Footer --> */}
    </div>
  );
};

export default Footer;